

.textoRobotti {
     background-color: whitesmoke;
    
}

.assunto {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.quadro {
    flex: 0 1 calc(100% - 1em);
    background-color: lightsteelblue;
    border-radius: 0.5rem;
    margin: 1rem;
    margin-right: 1rem;
    padding: 1rem;
    display:flex;
}




.whatColor  { background-color: #156ed0; }
.whyColor   { background-color: #1c90b9; }
.whereColor { background-color: #15d0d0; }
.howColor   { background-color: #14c693; }


.questionBox {
    flex: 0 1 calc(15% - 1em);
    padding: 0px;
    
}

.question {
    color: white;
    font-size: 2rem;
    writing-mode: vertical-lr;
    text-orientation: upright;
    text-transform: uppercase;
    margin: 0.5rem;
}

.what {
    flex: 0 1 calc(85% - 1em);
    font-size: medium;
    

}

.whatParagraph {
    margin: 0.5rem;
}


.listaVideos {
    background-color: lightgray;
    display:flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.video {
    flex: 0 1 calc(100% - 1em);
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    
}

.videoHeather {
    margin-top: 0rem;
    margin-bottom: 0rem;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 2rem;
}

.videoPlayer {
   
    padding-left: 1rem;
    padding-bottom: 1rem;

}



/* tela > ipad */
@media screen and (min-width: 768px) {
    .video {
        flex: 0 1 calc(45% - 1em);
     
    }
}

/* tela > ipad pro */
@media screen and (min-width: 1024px) {
    .quadro {
        flex: 0 1 calc(40% - 1em);
    }
}    

/* tela computador */
@media screen and (min-width: 1200px) {
    .quadro {
        flex: 0 1 calc(40% - 1em);
    }
    .video {
        flex: 0 1 calc(25% - 1em);
    }
    .question {
        font-size: 3rem;
    }
}


