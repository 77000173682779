.email {
    border: 1px solid white;
    border-radius: .5rem;
    background-color:rgba(52, 96, 128, 0.2);
    padding: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 10px; 
    margin-right: auto;
    margin-left: auto;
    text-align: left;
    width: 60%;
}

.sendEmailForm {
    border: none;
}


/*.legenda {
    text-align: center;
    color: rgba(52, 96, 128);
    font-size: 1.5rem;
} */

.info {
    padding-left: 10%;
}

.formLabel {
    color: rgba(52, 96, 128);
    font-size: 0.9rem;
    display: block;
    margin-top: 4px;
}

.txtbox {
    width: 90%;
}

.texto {
    width: 90%;
}

.btnEmail {
    margin-top: 4px;
    text-align: center;
    padding-right: 0.5rem;
}
.btnEnvia {
    text-align: center;
    background-color: rgba(52,96,128,0.2);
    border-radius: 3px;
    border: none;
    width: 100px;
    height: 30px;
    
}