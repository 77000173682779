
.App {
  text-align: left;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  
}

h1 {
    color: #346080;
    font-size: 2rem;
}

h2 {
   color: black;
   font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

.descricaoPrincipal {
  margin-right: auto;
  margin-left: auto;
  max-width: 60em;
  font-size: 1.25rem;
  line-height: 1.5;
  padding-right: 5rem;
  padding-left: 5rem;
  
}

@media screen and (max-width: 1023px) {
  .descricaoPrincipal {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  h1 {
    font-size: 1.5rem;
    }

  h2 {
    font-size: 1rem;
  }
}






