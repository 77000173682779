.card {
    border: 1px solid white;
    border-radius: .5rem;
    background-color:rgba(52, 96, 128, 0.2);
    padding: 0.5rem;
    margin: 3px;
    margin-bottom: 10px; 
    text-align: center;
    flex: 0 1 calc(100% - 1em); /* flex-grow 0 flex-shrink 1 width 24% */
}

.card img {
    margin-top: 2px;
    width: 5rem;
    height: 5rem;
}

.card p {
    margin: 1px 8px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
}


@media screen and (min-width: 40em) {
    .card {
        flex: 0 1 calc(50% - 1em);
    }
    
}

@media screen and (min-width: 60em) {
    .card {
        flex: 0 1 calc(24% - 1em);
    }
       
}




