.servico {
    display:flex;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    border-radius: .5rem;
}

.botoes {
    flex-direction: column;
    flex: 0 1 20%;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
    background-color:  rgba(52, 96, 128, 0.2);
}

.conteudo {
    flex: 0 1 80%;
    background-color:  rgba(52, 96, 128, 0.2);
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

@media screen and (max-width: 767px) {
    .servico {
        width: 100%;
        
    }
    
}