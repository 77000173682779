.cardlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

@media screen and (max-width: 1023px) {
    .cardlist {
        width: calc(100% - 1em);
    }
}
