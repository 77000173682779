.partners {
    margin: 2rem;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    
}

.partner {
    border: 1px solid white;
    border-radius: .5rem;
    background-color:rgba(52, 96, 128, 0.2);
    padding: 1rem;
    margin: 3px;
    margin-bottom: 10px; 
    text-align: center;
    flex: 0 1 calc(100% - 1em); /* flex-grow 0 flex-shrink 1 width 24% */
}

.partnerLogo {
    height:80px;
}

.partnerLogoSvg {
    height: 100px;
}
.partner p {
    font-size: 0.8rem;
}

.partner a {
    text-decoration: none;
    color: gray;
    font-size: 0.8rem;
}

@media screen and (min-width: 40em) {
    .partner {
        flex: 0 1 calc(50% - 1em);
    }
    
}

@media screen and (min-width: 60em) {
    .partner {
        flex: 0 1 calc(24% - 1em);
    }
       
}
