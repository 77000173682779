
.descricaoServico {
    display:flex;
    line-height: 100%;
    border-radius: .5rem;
    padding: 2rem;
    text-align: center;
}

.iconeServico {
    flex: 0 1 20%;
}

.iconeServico img {
    width: 10rem;
    height: 10rem;
} 



.descricaoServico h4 {
    text-align: left;
    color:  rgba(52, 96, 128);
    margin-left: 1rem;
}



.textoServico p {
    margin: 16px 8px;
    padding-left: 20px;
    padding-right: 10px;
    text-align: left; 
}

@media screen and (max-width: 1023px) {
    .iconeServico {
        flex: 0 1 100%;
    }

    .textoServico {
        flex: 0 1 100%;
    }    

    .descricaoServico {
        flex-wrap: wrap;
    }
   
    .descricaoServico img {
        width: 3rem;
        height: 3rem;
    }

    .textoServico p {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        font-size: 0.8rem;
    }
    
}

@media screen and (max-width: 767px) {
    
    .descricaoServico {
        padding-top: 1rem;
        padding-left: 1rem;
    }
   
    .descricaoServico h1 {
        font-size: 1rem;
    }

    .descricaoServico h4 {
        font-size: 1rem;
    }

}
