.fundo {
    background-color: whitesmoke;
    color: black;
    width: calc(100% - 0.5em);
    font-size: 0.8rem;
    border-top: solid 2px gray;
    padding-left: 0.2rem;
    margin-top: 2rem;
    }


.objetos {
    display: flex;
    justify-content: space-around;
      
}

.escritorio {
    display:block;
    box-sizing: border-box;
    flex: 0 1 calc(40% - 1em);
    
    
}

.escritorioh2 {
    font-size: 1rem;
}

.escritorio a {
    color: black;
    text-decoration: none;
}

.contato {
    margin: 0.2rem;
}

.midiasSociais {
    display: inline;
    color: black;
    padding-top: 20px;
    flex: 0 1 calc(20% - 1em);
    
    
}
.iconeMidiaSocial {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.2rem;
}

.logoFooter {
        margin-right: 2rem;
        
    
}

@media (max-width: 768px) {
    .objetos {
        flex-wrap: wrap;
    }
    .escritorio {
        flex: 0 1 calc(100% - 1em);
    }
    .escritorio h1 {
        text-align: center;
    }
    .contato {
        text-align: center;
    }
    .midiasSociais {
        flex: 0 1 calc(100% - 1em);
    }
}