.blog {
    text-align: center;
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
}

.blog p {
    text-align: justify;
}
.blog h4 {
    color:#346080;
    text-align: left;
}

.blog ul {
    text-align: justify;
}

.writer {
    color:#346080;
    font-weight: bold;
    margin-bottom: 0rem;
}
.writerPosition {
    color: gray;
    margin-top: 0rem;
    margin-bottom: 0.1rem;
}
.writerEmail {
    color:gray;
    margin-top: 0rem;
}

.writerEmail a {
    text-decoration: none;
}

.articleImage {
    text-align: center;
    height: 40vh;
}

.articlePicture {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1023px) {
    .blog {
        width: calc(100% - 1em);
    }
}