html {
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  height:100%;
}

