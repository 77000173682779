.toolbar {
    position:fixed;
    top:0;
    left:0;
    height:90px;
    width:100%;
    background-color: white;
    border-bottom-color:   black ;
    border-bottom-style: solid;
    border-bottom-width: 2px;
}

.spacer {
    flex: 1;
}
.toolbar_logo {
    margin-left: 0.2rem;

}
.toolbar_navigation{
    display:flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.toolbar_navigation_items ul {
    list-style: none;
    margin:0;
    padding:0;
    display:flex;
}

.toolbar_navigation_items ul li{
    padding:0.5rem;
    height: 100%;
}

.menuItem {
    color:  #346080;
    border: white;
    border-radius: 3px;
    background-color: white;
    height: 100%;
    font-size: 1rem;
    padding: 7px 13px;
}

.toolbar_navigation_items ul li a {
    color:  #346080;
    text-decoration: none;
    border: white;
    border-radius: 3px;
    background-color: white;
    height: 100%;
    font-size: 1rem;
    padding: 7px 13px;
}



.toolbar_navigation_items a:hover,
.toolbar_navigation_itens a:active {
    background-color: #83c3eb;
    transition: .5s;
}

.selected a{
    background-color: #83c3eb;
}


/*.toolbar_navigation_items button:hover {
    cursor: pointer;
}*/


@media (max-width: 768px) {
    .toolbar_navigation_items {
        display: none;
    }
}

@media (min-width: 769px) {
    .toggle-button {
        display: none;
    }
    .toolbar_logo {
        margin-left: 0;
    
    }
}