.contact {
    color: black;
    width:calc(100% - 1em);
    font-size: 0.8rem;
    margin-left: 1px;
    margin-right: 1px;
    }


.country {
    display: flex;
    justify-content: space-around;
    
}

.name {
    display:block;
    box-sizing: border-box;
    flex: 0 1 calc(30% - 1em);
    padding-left: 2rem;
    
}

.name a {
    color: black;
    text-decoration: none;
}


@media (max-width: 410px) {
    .contact {
        font-size: x-small;
    }
    .country h1 {
        font-size: 1rem;
    }
    .name h4 {
        font-size: small;
    }
}