.aviso {
    background-color:  rgba(52,96,128,0.4);
    color: black;
    width: 40%;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
    text-align: center;
}

.erro {
    background-color: rgba(247, 76, 76, 0.2);
    color: black;
    width: 40%;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
    padding: 10px;
    text-align: center;
}
