.hello_image {
    background-image: url("foto_principal.png");
    background-size: 100%;
    height: 50vh;
    
}

.medium_image {
    height: 40vh;
}

.small_image {
    height: 25vh;
}

.nuvem {
    background-color: rgba(0,0,0,0.6);
    height: 100%;
    
}

.titulo {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; 
    padding-left: 5rem;
    padding-right: 5rem;
}

.tituloh1 {
    color:#346080;
}

.tituloh2 {
    color: #9fc2df;
}

@media screen and (max-width: 1023px) {
    .hello_image {
        height: 60vh;
        
    }
    .medium_image {
        height: 45vh;
    }
    .small_image {
        height: 30vh;
    }
}

@media screen and (max-width: 320px) {
    .hello_image {
        height: 70vh;
    }
    .medium_image {
        height: 70vh;
    }
    .small_image {
        height: 40vh;
    }
}
