.robozinho {
    position: fixed;
    /*bottom: 10px;
    right: 10px; */
    background-color: transparent;
    z-index: 201;
    animation-name: desce;
    animation-duration: 3s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

@keyframes desce {
    0%  {right:10px; top:0px;}
   20%  {right:10px; top:25vh;} 
   40%  {right:10px; top:50vh;}
   60%  {right:10px; top:75vh;}
   80%  {right:10px; top:90vh;} 
  100%  {right:10px; bottom:10px;}
}

.roboIframe {
    width: 60px;
    height: 60px;
    background-color: transparent;
    border-radius: 2rem;
}

.roboIframeAberto {
    width: 301px;
    height: 70vh;
    background-color: whitesmoke;
    border:1px whitesmoke solid;
}

.falaRobo {
    background-color: #346080;
    color: white;
    border-radius: 0.5rem;
    font-size: small;
    width: 150px;
    padding: 1rem;
    position: fixed;
    z-index: 201;
    animation-name: aparece;
    animation-duration: 5s;
    animation-delay: 5s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

@keyframes aparece {
    0%  {right:10px; bottom:0px;}
    20%  {right:10px; bottom:40px;}
    40%  {right:10px; bottom:40px;}
    60%  {right:10px; bottom:40px;}
    80%  {right:10px; bottom:40px;}
    100% {right:10px; bottom:-200px;}
}

.palavrasRobo {
    margin:0px;
}