.news {
    /*display:flex;*/
    
    margin-right: auto;
    margin-left: auto;
    border-radius: .5rem;
    text-align: justify;
    
}

.artigo {
    background-color: white;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.listaArtigos {
    margin-left: auto;
    margin-right:auto;
    width: 80%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.listaArtigos h2 {
    color: rgb(52, 96, 128);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.blogTeaser {
    /*display: flex;*/
    font-size: xx-small;
      
    
}

.blogTeaser h5 {
    color: rgb(52, 96, 128);
    font-size: small;
    margin-bottom: 0px;
}


.botaoTeaser {
    flex: 0 1 calc(100% - 1em);
    border: 1px solid rgba(52, 96, 128, 0.2);
    border-radius: .5rem;
    background-color: white;
    /*background-color: rgba(52, 96, 128, 0.2);*/
    padding-right: 2px; 
   /*text-align: center;*/
    margin: 0.2rem;
    cursor: pointer;
}

.botaoTeaserSelected {
    background-color:  rgba(52, 96, 128, 0.2);
    border-left: none;
    cursor:auto;
}


.botaoTeaserConteudo {
    
    padding: 1rem;
}

.signBox {
    flex: 0 1 calc(100% - 1em);
    border: 1px solid rgba(52, 96, 128, 0.2);
    border-radius: .5rem;
    background-color: rgba(52, 96, 128, 0.4);
    padding-right: 2px; 
    margin: 0.2rem;
    padding: 0.5rem;
    
    
}

.signBox label {
    text-align:left;
    font-size: small;
    padding-top: 4px;
    
}

.mostra {
    display: flex;
    font-size: small;
    margin-top: 2px;
    padding-left: 1.2rem;
    
    
}

.labelEmail {
    margin: 2px;
    color: rgba(52, 96, 128);
}

.btnSign {
    text-align: center;
    background-color: rgba(52,96,128,0.4);
    border-radius: 3px;
    border: none;
    width: 100px;
    height: 30px;
    margin-top: 4px;
}

.esconde {
    display: none;
}


@media screen and (min-width: 40em) {
    .botaoTeaser {
        flex: 0 1 calc(50% - 1em);
    }
    
}

@media screen and (min-width: 60em) {
    .news {
        display:flex;
    }
    .blogTeaser {
        display: block;
        font-size: xx-small;
    }
    /*.botaoTeaser {
        flex: 0 1 calc(24% - 1em);
    }*/
       
}


