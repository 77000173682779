.botaoServico {
    border: 1px solid rgba(52, 96, 128, 0.2);
    border-radius: .5rem;
    background-color: white;
    /*background-color: rgba(52, 96, 128, 0.2);*/
    padding-right: 2px; 
   /*text-align: center;*/
    flex: 0 1 calc(100% - 1em); /* flex-grow 0 flex-shrink 1 width 24% */
}

.botaoServicoSelected {
    background-color:  transparent;
    border: none;
}

.botaoServico img {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 3.5rem;
    height: 3.5rem;
}

.conteudoBotao {
    display: flex;
    cursor: pointer;
    
}

.titulobotao {
    font-size: smaller;
}

@media screen and (max-width: 767px) {
    .titulobotao {
        font-size: 0rem;
    }
    .botaoServico img {
        margin-left: 1rem;
        margin-right: 1rem;
        width: 3rem;
        height: 3rem;
    }
}