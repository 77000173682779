.side-drawer {
    height: 100%;
    background: white;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position:fixed;
    top: 0;
    left: 0;
    width: 60%;
    max-width: 300px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
   
}
.side-drawer.open {
   transform: translateX(0);
}
.side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
}

.side-drawer li {
    margin: 0.5rem;
}

.sideMenuItem {
    color:  #346080;
    border: white;
    border-radius: 3px;
    background-color: white;
    font-size: 1.2rem;
    padding: 7px 13px;
    width: 100%;
}

.side-drawer ul li a {
    text-decoration: none;
    color:  #346080;
    border: white;
    border-radius: 3px;
    background-color: white;
    font-size: 1.2rem;
    padding: 7px 13px;
    width: 100%;
}



.side-drawer a:hover,
.side-drawer a:active {
    
    background-color: #83c3eb;
    transition: .5s
}

.sideSelected {
    background-color: #83c3eb;
}

