.descLeader {
    margin-right: auto;
    margin-left: auto;
    max-width: 60em;
    font-size: 1.25rem;
    line-height: 1.5;
}

.descLeader h1 {
  line-height: 1.25;
  font-size: 3rem;
  color: #9fc2df;   
  margin-bottom: 0;
  text-align: center;
}

.descLeader h6 {
    color: gray;
    text-align: right;
    margin-top: 0;
    margin-right: 0.5rem;
}

.descLeader p {
    margin: 1rem;
}